<template>
  <div class="flex flex-col gap-4">
    <span v-if="errorMessage" class="px-2 py-1 text-red-600 bg-red-100 rounded">
      {{ errorMessage }}
    </span>
    <h4 class="font-semibold">{{ $t("Payment Method") }}</h4>
    <div class="flex flex-wrap gap-2">
      <label
        class="cursor-pointer"
        v-for="gateway in gateways"
        :key="gateway.id"
        :for="gateway.id"
      >
        <input
          class="hidden"
          v-model="form.gateway"
          type="radio"
          name="gateway"
          :value="gateway.id"
          :id="gateway.id"
        />
        <span
          :class="{
            'border-red-500': form.gateway == gateway.id,
          }"
          class="flex items-center gap-3 px-4 py-2 font-semibold border divide-x-2 rounded-full"
        >
          <img :src="gateway.image" class="h-6" alt="" />
          <span class="pl-4">{{ gateway.name }}</span>
        </span>
      </label>
    </div>

    <div class="flex flex-col gap-4">
      <h4 class="font-semibold">{{ $t("Payment Data") }}</h4>

      <div class="p-4 rounded bg-zinc-100">
        <p>{{ currentGateway?.instruction }}</p>
      </div>
      <div v-if="currentGateway?.inputs.length">
        <div
          v-for="input in currentGateway.inputs"
          :key="input.name"
          class="mb-4"
        >
          <label class="block mb-2 text-sm font-medium">
            {{ $t(input.name) }}*
          </label>
          <input
            v-model="form[input.name]"
            :type="input.type || 'text'"
            :placeholder="input.placeholder"
            :maxlength="input.validation?.maxLength"
            :class="[
              'w-full p-2 border rounded',
              errors[input.name] ? 'border-red-500' : 'border-gray-300',
            ]"
            @blur="validateField(input)"
          />
          <span v-if="errors[input.name]" class="text-sm text-red-600">
            {{ errors[input.name] }}
          </span>
        </div>
      </div>

      <div>
        <!-- <label class="block mb-2 text-sm font-medium"
          >{{ $t("Amount") }}*</label
        > -->
        <InputMoney
          v-model="form.amount"
          id="amount"
          :placeholder="$t('Amount')"
          :label="$t('Amount') + '*'"
          :error="errors.amount"
          @blur="validateAmount"
        />
        
      </div>

      <div class="flex justify-end mt-4">
        <slot :submit="setUserBalance" :processing="loading">
          <button
            type="submit"
            @click="handleSubmit"
            class="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded hover:bg-blue-600"
            v-if="!loading"
          >
            {{ $t("Recharge") }}
          </button>
          <button
            type="button"
            class="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded opacity-50 cursor-not-allowed"
            disabled
            v-else
          >
            {{ $t("Processing") }}...
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import AgentStoreModule from "@/pages/seller/agents/AgentStoreModule";
import toastr from "toastr";
import { ref, computed, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import InputMoney from "./InputMoney.vue";
const { t } = useI18n();
const loading = ref(false);
const errorMessage = ref("");
const errors = ref({});

const emit = defineEmits(["pay"]);
const props = defineProps({
  gateway: {
    type: String,
    default: "mpesa",
  },
});

const form = ref({
  gateway: props.gateway,
  amount: 1000,
  phone: "",
});

const gateways = ref([
  {
    id: "mpesa",
    image: "/assets/img/mpesa.svg",
    name: "MPesa",
    instruction: t("mpesa_gateway_instructions"),
    inputs: [
      {
        name: "phone",
        placeholder: "84/85xxxxxxx",
        validation: {
          required: true,
          pattern: /^8[45][0-9]{7}$/,
          minLength: 9,
          maxLength: 9,
        },
      },
    ],
  },
]);

const currentGateway = computed(() => {
  return gateways.value.find(({ id }) => id == form.value.gateway);
});

function validateField(input) {
  const value = form.value[input.name];
  const validation = input.validation;

  if (validation?.required && !value) {
    errors.value[input.name] = `${t(input.name)} é obrigatório.`;
    return;
  }

  if (validation?.pattern && !validation.pattern.test(value)) {
    errors.value[input.name] = `${t(input.name)} inválido.`;
    return;
  }

  if (validation?.minLength && value.length < validation.minLength) {
    errors.value[input.name] = `${t(input.name)} deve ter pelo menos ${
      validation.minLength
    } caracteres.`;
    return;
  }

  if (validation?.maxLength && value.length > validation.maxLength) {
    errors.value[input.name] = `${t(input.name)} não pode exceder ${
      validation.maxLength
    } caracteres.`;
    return;
  }

  errors.value[input.name] = "";
}

function validateAmount() {
  if (!form.value.amount || form.value.amount <= 0) {
    errors.value.amount = "O valor deve ser maior que zero.";
  } else {
    errors.value.amount = "";
  }
}

async function handleSubmit() {
  errors.value = {};

  // console.log("====================================");
  // console.log(Object.keys(currentGateway.value?.inputs || []));
  // console.log("====================================");
  Object.keys(currentGateway.value?.inputs || []).forEach((index) => {
    validateField(currentGateway.value.inputs[index]);
  });
  validateAmount();

  if (Object.values(errors.value).some((error) => error)) {
    toastr.error("Verifique os campos obrigatórios.");
    return;
  }

  await setUserBalance();
}

async function setUserBalance() {
  loading.value = true;
  errorMessage.value = "";
  try {
    const { data } = await AgentStoreModule.actions.setUserBalance(form.value);
    toastr.success("Pagamento processado com sucesso");
    emit("pay", data);
  } catch (error) {
    errorMessage.value =
      error.response?.data?.message || error.message || "Erro inesperado.";
    toastr.error(errorMessage.value);
  } finally {
    loading.value = false;
  }
}
</script>
