import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
// import { MainLayout } from "@/layouts"; 
import { GuestRoutes } from "./guest";
import { AdminRoutes } from "./admin";
import { SellerRoutes } from "./seller";

const routes = [
  SellerRoutes,
  GuestRoutes,
  AdminRoutes,
  {
    path: '/auth/google/call-back/:state?',
    component: () => import("@/pages/auth/AuthLoading.vue"),
    meta: {
      title: "Hello google"
    }
  },
  {
    path: "/auth/google/:token",
    name: "google-auth-callback",
    component: () => import("@/pages/auth/GoogleAuthCallbackPage.vue"),
    meta: {
        middleware: "guest",
    },
},
{
  path: '/:pathMatch(.*)*', // Captura todas as rotas desconhecidas
  name: 'NotFound',
  component: () => import('@/pages/site/NotFound.vue'),
  meta: {
    title: 'Página não encontrada',
  },
},
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, top: 0 };
    }
  },
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.meta.requiresAuth && !isAuthenticated()) {
    // redirect user to the login page
    next('/login');
  } else if (to.meta.role === 'Superadmin' && !isAdmin()) {
    // redirect non-admin users trying to access Superadmin routes
    next('/');
  } else {
    // allow user to access the route
    next();
  }

});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
    return true;
  } else {
    return false;
  }
  // return true;
}

function isAdmin() {
  if (store.state.auth.authenticated && store.state.auth.role == 'Superadmin') {
    return true;
  } else {
    return false;
  }
}

// f

export default router;
