// import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://terrenos.co.mz/Api/api/" ;

// const URL_API = "http://localhost:8000/api/" ;
const URL_API = process.env.VUE_APP_URL_API;



export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async addAgent(dataAgent) {
      try {
        let data = new FormData();

        data.append("fullName", dataAgent.fullName);
        data.append("email", dataAgent.email);
        data.append("gender", dataAgent.gender);
        data.append("doc_number", dataAgent.doc_number);
        data.append("nactionality", dataAgent.nactionality);
        data.append("birthDate", dataAgent.birthDate);
        data.append("childhood_location", dataAgent.childhood_location);
        data.append("marital_status", dataAgent.marital_status);
        data.append("contact", dataAgent.contact);
        data.append("contact_alternative", dataAgent.contact_alternative);
        data.append("whatsapp", dataAgent.whatsapp);
        data.append("provice_id", dataAgent.provice_id);
        data.append("district_id", dataAgent.district_id);
        data.append("facebook", dataAgent.facebook);
        data.append("twitter", dataAgent.twitter);
        data.append("profilePic", dataAgent.profilePic);

        const resp = await axios.post(URL_API + "Agent", data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async provinces() {
      try {
        const resp = await axios.get(URL_API + "provinces", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async districtsByProvinceId(id) {
      try {
        const resp = await axios.get(URL_API + `district/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAgents(queryParams) {
      try {
        const resp = await axios.get(URL_API + `Agent?page=${queryParams.page}&search=${queryParams.search}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllAgents() {
      try {
        const resp = await axios.get(URL_API + `AllAgent`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAgentById(id) {
      try {
        const resp = await axios.get(URL_API + `Agent/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async updateAgent(dataAgent, id) {
      try {
        let data = new FormData();

        data.append("fullName", dataAgent.fullName);
        data.append("email", dataAgent.alternative_email);
        data.append("gender", dataAgent.gender);
        data.append("doc_number", dataAgent.doc_number);
        data.append("nactionality", dataAgent.nactionality);
        data.append("birthDate", dataAgent.birthDate);
        data.append("childhood_location", dataAgent.childhood_location);
        data.append("marital_status", dataAgent.marital_status);
        data.append("contact", dataAgent.contact);
        data.append("contact_alternative", dataAgent.contact_alternative);
        data.append("whatsapp", dataAgent.whatsapp);
        data.append("provice_id", dataAgent.provice_id);
        data.append("district_id", dataAgent.district_id);
        data.append("facebook", dataAgent.facebook);
        data.append("twitter", dataAgent.twitter);
        data.append("id", id);
        data.append("user_id", dataAgent.user_id);
        data.append("profilePic", dataAgent.profilePic);

        const resp = await axios.post(URL_API + `Agentupdate`, data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async getUserBalance() {
      return await axios.get(URL_API + `payments`, { headers: authHeader() });
    },
    async setUserBalance(data) {
      return await axios.post(URL_API + `payments`, data, { headers: authHeader() });
    },

    async getUserData() {
      try {
        const resp = await axios.get(URL_API + `getUser`, { headers: authHeader() });
        return resp;
      } catch (error) {
        return error;
      }
    },
    async updateUser(dataAgent) {
      try {
        let data = new FormData();

        data.append("fullName", dataAgent.fullName);
        data.append("email", dataAgent.alternative_email);
        data.append("gender", dataAgent.gender);
        data.append("doc_number", dataAgent.doc_number);
        data.append("nactionality", dataAgent.nactionality);
        data.append("birthDate", dataAgent.birthDate);
        data.append("childhood_location", dataAgent.childhood_location);
        data.append("marital_status", dataAgent.marital_status);
        data.append("contact", dataAgent.contact);
        data.append("contact_alternative", dataAgent.contact_alternative);
        data.append("whatsapp", dataAgent.whatsapp);
        data.append("provice_id", dataAgent.provice_id);
        data.append("district_id", dataAgent.district_id);
        data.append("facebook", dataAgent.facebook);
        data.append("twitter", dataAgent.twitter);
        data.append("user_id", dataAgent.user_id);
        data.append("profilePic", dataAgent.profilePic);

        const resp = await axios.post(URL_API + `updateUser`, data, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async clienteRequireAgents(data) {
      try {
        const query = '?' + Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const resp = await axios.get(URL_API + 'getAgents' + query);
        return resp;
      } catch (error) {
        return error;
      }
    },

    // Cria uma nova franquias
    async addfranchise(data) {
      return await axios.post(URL_API + `franchise`, data);

    },

    // Cria um novo agente sem precisar criar conta
    async beAgent(data) {
      return await axios.post(URL_API + `beAgent`, data);

    },

    // buscar franquias
    async getfranchise() {
      return await axios.get(URL_API + `getfranchise`, { headers: authHeader() });

    },

    // buscar agentes sem conta no sistema
    async getbeAgent() {
      return await axios.get(URL_API + `getbeAgent`, { headers: authHeader() });

    },
  },
};
