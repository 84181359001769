<template>
  <div class="flex flex-col">
    <label v-if="label" :for="id" class="block mb-2 text-sm font-medium">
      {{ label }}
    </label>
    <input
      :id="id"
      type="text"
      :placeholder="placeholder"
      class="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
      v-model="formattedValue"
      @input="onInput"
      @blur="$emit('blur')"
      :class="inputClass"
    />
    <span v-if="error" class="mt-1 text-sm text-red-600">{{ error }}</span>
  </div>
</template>
  
  <script setup>
import { ref, watch, defineProps, defineEmits } from "vue";

// Props
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  id: {
    type: String,
    default: "input-money",
  },
  label: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: "0,00",
  },
  error: {
    type: String,
    default: null,
  },
  inputClass: {
    type: String,
    default: "",
  },
});

// Emit eventos para sincronização
const emit = defineEmits(["update:modelValue"]);

// Variável reativa para o valor formatado
const formattedValue = ref("");

// Atualizar o valor formatado quando `modelValue` mudar
watch(
  () => props.modelValue,
  (newValue) => {
    formattedValue.value = formatToCurrency(newValue || 0);
    onInput();
  },
  { immediate: true }
);

// Função chamada ao digitar no input
function onInput() {
  // Remover caracteres inválidos e manter apenas números e pontos
  const rawValue = formattedValue.value.replace(/[^\d]/g, "");

  // Converter o valor bruto para número
  const numericValue = parseFloat(rawValue) / 100 || 0;

  // Atualizar o valor formatado
  formattedValue.value = formatToCurrency(numericValue);

  // Emitir o valor numérico puro
  emit("update:modelValue", numericValue);
}

// Função para formatar o número no formato monetário (ex.: 1.234,56)
function formatToCurrency(value) {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
</script>
  