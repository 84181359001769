<template>
  <div class="flex h-screen bg-zinc-100 dark:bg-zinc-800">
    <!-- Sidebar -->
    <!-- {{ userLogged }} -->
    <aside
      :class="{
        'translate-x-0 w-56': isSidebarOpen,
        '-translate-x-full md:translate-x-0': !isSidebarOpen,
      }"
      class="fixed top-0 min-w[200px] bottom-0 z-50 transition-transform duration-300 bg-white dark:bg-zinc-900 md:m-4 rounded-r-2xl md:rounded-2xl md:relative"
    >
      <div class="flex items-start justify-between p-4">
        <a href="/"  class="">
          <img src="/TerrenosLogo.png" class="h-20" alt="Terrenos Logo" />
        </a>

        <button
          @click="toggleSidebar"
          class="relative z-20 text-2xl text-zinc-600 dark:text-white md:hidden hover:text-blue-600"
        >
          <span>&times;</span>
        </button>
      </div>
      <nav class="mt-4">
        <ul>
          <li
            v-for="item in menuItems"
            :key="item.label"
            class="px-4 my-2"
            :class="{
              'border-blue-600 border-l-4': item.activeRoutes.includes(
                $route.name
              ),
            }"
            @click="closeSidebar"
          >
            <RouterLink
              :to="item.route"
              :class="{
                'text-blue-600 bg-blue-50 dark:bg-blue-600 dark:text-white':
                  item.activeRoutes.includes($route.name),
                'text-zinc-600 hover:text-blue-600 dark:text-white hover:bg-blue-50 dark:hover:bg-blue-600':
                  !item.activeRoutes.includes($route.name),
              }"
              class="flex items-center gap-3 p-2 rounded-lg"
              @click="setActiveMenu(item.label)"
            >
              <i
                :class="[
                  item.icon,
                  {
                    'mx-auto': !isSidebarOpen,
                  },
                ]"
                class="text-lg"
              ></i>
              <span v-if="isSidebarOpen" class="font-medium">{{
                $t(item.label)
              }}</span>
            </RouterLink>
          </li>
        </ul>
      </nav>
    </aside>

    <!-- Main Content -->
    <div class="flex flex-col flex-1 overflow-hidden">
      <!-- Header -->
      <header class="relative z-20 flex items-center justify-between px-4 py-5">
        <!-- Botão Toggle Sidebar -->
        <div class="flex items-center gap-4">
          <a href="/" class="lg:hidden">
            <img src="/logotipo.png" class="h-10" alt="Terrenos Logo" />
          </a>
          <button
            @click="toggleSidebar"
            class="relative z-20 px-4 py-3 bg-white border rounded-full text-zinc-600 dark:border-0 dark:text-white dark:bg-zinc-900 hover:text-blue-600"
          >
            <svg
              v-if="isSidebarOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                d="M21 3C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21ZM7 5H4V19H7V5ZM20 5H9V19H20V5Z"
              ></path>
            </svg>

            <i v-else class="fa fa-bars"></i>
          </button>
          <div class="relative hidden lg:block">
            <input
              type="text"
              id="floating_outlined"
              class="block px-4 pb-2 pt-3 w-full text-sm text-zinc-900 dark:bg-zinc-900 bg-transparent rounded-full min-w-[300px] border-1 bg-white border-zinc-200 appearance-none dark:text-white dark:border-zinc-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              :placeholder="$t('PageFilter.Search') + '...'"
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-zinc-500 dark:text-zinc-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-zinc-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >{{ $t("PageFilter.Search") }}</label
            >
            <button
              type="button"
              class="absolute inset-y-0 flex items-center end-0 pe-3"
            >
              <svg
                class="w-4 h-4 text-zinc-500 dark:text-zinc-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <!-- Outros elementos do header -->
        <div
          class="flex items-center p-2 space-x-4 bg-white rounded-full dark:bg-zinc-900"
        >
          <div class="flex items-center space-x-4">

              <button
                @click="openPaymentModal('mpesa')"
                class="flex items-center gap-3 px-2 py-2 font-semibold transition-all border rounded-full text-primary-600 border-primary-600 dark:text-primary-400 md:px-4 hover:text-white hover:bg-primary-600"
              >
              <svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M3.00488 2.99979H21.0049C21.5572 2.99979 22.0049 3.4475 22.0049 3.99979V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979ZM20.0049 10.9998H4.00488V18.9998H20.0049V10.9998ZM20.0049 8.99979V4.99979H4.00488V8.99979H20.0049ZM14.0049 14.9998H18.0049V16.9998H14.0049V14.9998Z"></path></svg>
                <!-- <img src="/assets/img/mpesa.svg" class="h-6" alt="" />-->
                <span  class="hidden md:block">{{ $t("Recharge Account") }}</span>
              </button>
            <button
              id="theme-toggle"
              type="button"
              class="text-zinc-500 dark:text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-700 focus:outline-none focus:ring-4 focus:ring-zinc-200 dark:focus:ring-zinc-700 rounded-full text-sm p-2.5 da"
            >
              <svg
                id="theme-toggle-dark-icon"
                class="w-5 h-5 dark:hidden"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                ></path>
              </svg>
              <svg
                id="theme-toggle-light-icon"
                class="hidden w-5 h-5 dark:block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <router-link
              :to="{ name: 'seller.notifications' }"
              class="relative px-3 py-2 rounded-full bg-zinc-100 dark:bg-zinc-800"
            >
            <span class="absolute right-0 px-1 py-0 text-xs text-white bg-red-600 rounded-full -top-1" v-if="userLogged.notifications_count">{{ userLogged.notifications_count > 99 ? '+99' : userLogged.notifications_count }}</span>
              <i
                class="text-zinc-600 fa fa-bell hover:text-blue-600 dark:text-zinc-300"
              ></i>
            </router-link>
            <div class="relative">
              <img
                @click="toggleDropdown"
                :src="
                  userLogged.profilePic
                    ? userLogged.profilePic
                    : `https://ui-avatars.com/api/?name=${userLogged.fullName}&color=7F9CF5&background=EBF4FF`
                "
                alt="avatar"
                class="rounded-full cursor-pointer w-9 h-9 bg-zinc-100"
              />
              <ul
                v-show="isDropdownOpen"
                class="absolute right-0 mt-2 overflow-hidden bg-white shadow-md rounded-xl"
              >
                <li
                  class="flex flex-col items-center gap-2 px-4 pt-4 pb-2 text-center"
                >
                  <img
                    @click="toggleDropdown"
                    :src="
                      userLogged.profilePic
                        ? userLogged.profilePic
                        : `https://ui-avatars.com/api/?name=${userLogged.fullName}&color=7F9CF5&background=EBF4FF`
                    "
                    alt="avatar"
                    class="w-20 h-20 rounded-full cursor-pointer min-w-20 bg-zinc-100"
                  />
                  <h4 class="font-semibold">{{ userLogged.fullName }}</h4>
                  <p class="">{{ userLogged.alternative_email }}</p>
                </li>
                <li>
                  <hr />
                </li>
                <li class="flex justify-between gap-4 px-4 py-3">
                  <span>{{ $t("Balance") }}</span>
                  <span class="px-1 text-white truncate rounded bg-zinc-600"
                    >{{ userLogged.balance }} MZN</span
                  >
                </li>
                <li>
                  <hr />
                </li>
                <li
                  v-for="action in dropdownActions"
                  :key="action.label"
                  class="px-4 py-2 hover:bg-zinc-100"
                >
                  <RouterLink
                    :to="action.route"
                    v-if="action.route != 'logout'"
                    class="flex items-center w-full gap-2 text-zinc-600"
                  >
                    <i :class="action.icon"></i>
                    <span>
                      {{ $t(action.label) }}
                    </span>
                  </RouterLink>
                  <button
                    v-if="action.route == 'logout'"
                    @click="logout"
                    class="flex items-center w-full gap-2 text-zinc-600"
                  >
                    <i :class="action.icon"></i>
                    <span>
                      {{ $t(action.label) }}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <!-- Dashboard Content -->
      <main class="flex-1 overflow-y-auto">
        <!-- <h2 class="mb-4 text-xl font-bold">Dashboard Content</h2> -->
        <!-- Add dashboard components here -->

        <!-- <slot></slot> -->

        <router-view></router-view>
      </main>

      
    <Modal title="Recarregar a conta" :footer-hide="true" v-model="showModal">
      <RechargeAccountComponent :gateway="gateway" @pay="onPay" />
    </Modal>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import RechargeAccountComponent from "@/components/RechargeAccountComponent.vue";
import { ref } from "vue";
const userLogged = ref({
  ...store.state.auth.user,
  balance: store.state.auth?.user?.user?.wallet?.balance || 0,
});

const showModal = ref(false);
const gateway = ref("mpesa");

function openPaymentModal(_gateway = "mpesa") {
  showModal.value = true;
  gateway.value = _gateway;
}
</script>
<script>
// import { initFlowbite } from "flowbite";
// import { onMounted } from "vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isSidebarOpen: JSON.parse(localStorage.getItem("isSidebarOpen")) || false,
      isDropdownOpen: false,
      activeMenu: "Dashboard",
      menuItems: [
        {
          label: "Dashboard",
          icon: "fa fa-th-large",
          route: { name: "seller.dashboard" },
          activeRoutes: ["seller", "seller.dashboard"],
        },
        {
          label: "Products",
          icon: "fa fa-tags",
          route: { name: "seller.products" },
          activeRoutes: [
            "seller.products",
            "seller.add-product",
            "seller.edit-product",
          ],
        },

        // {
        //   label: "Subscription",
        //   icon: "fa fa-cube",
        //   route: "#",
        //   activeRoutes: ["seller.subscription"],
        // },
        {
          label: "Account",
          icon: "fa fa-user",
          route: { name: "seller.profile" },
          activeRoutes: ["seller.profile"],
        },
        // { label: "Products", icon: "fa fa-box", route: '' },
        {
          label: "Notifications",
          icon: "fa fa-bell",
          route: { name: "seller.notifications" },
          activeRoutes: ["seller.notifications"],
        },
        // { label: "Sales Platform", icon: "fa fa-store", route: '' },
      ],
      dropdownActions: [
        {
          label: "Profile",
          icon: "fa fa-user",
          route: { name: "seller.profile" },
        },
        // { label: "Settings" },
        { label: "Logout", icon: "fa fa-sign-out", route: "logout" },
      ],
    };
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      this.signOut();
      localStorage.removeItem("user");
      // this.$router.push({ name: "Home" });
      window.location.href = "/";
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      localStorage.setItem("isSidebarOpen", this.isSidebarOpen);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setActiveMenu(label) {
      this.activeMenu = label;
    },
    closeSidebar() {
      if (!this.isSidebarOpen && window.innerWidth < 1024) {
        this.isSidebarOpen = true;
        localStorage.setItem("isSidebarOpen", this.isSidebarOpen);
      }
    },
    toogleTheme() {
      if (
        localStorage.getItem("color-theme") === "dark" ||
        (!("color-theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      var themeToggleBtn = document.getElementById("theme-toggle");
      themeToggleBtn.addEventListener("click", function () {
        if (localStorage.getItem("color-theme")) {
          if (localStorage.getItem("color-theme") === "light") {
            document.documentElement.classList.add("dark");
            localStorage.setItem("color-theme", "dark");
          } else {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("color-theme", "light");
          }
        } else {
          if (document.documentElement.classList.contains("dark")) {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("color-theme", "light");
          } else {
            document.documentElement.classList.add("dark");
            localStorage.setItem("color-theme", "dark");
          }
        }
      });
    },
  },
  mounted() {
    this.toogleTheme();
  },
};

// onMounted(() => {
//   initFlowbite();
// });
</script>

<style>
body {
  font-family: "Inter", sans-serif;
}
</style>
