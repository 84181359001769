import DefaultLayout from "@/layouts/DefaultLayout.vue";

export const GuestRoutes = {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    meta: {
        title: "Home"
    },
    children: [
        {
            path: "/",
            name: "home",
            component: () => import("@/pages/site/HomePage.vue"),
            meta: {
                title: "Trespasse de Espaços"
            }
        },
        {
            path: "/contact",
            name: "contact",
            component: () => import("@/pages/site/ContactPage.vue"),
            meta: {
                title: "Trespasse de Espaços"
            }
        },
        {
            path: "/request-property",
            name: "request-property",
            component: () => import("@/pages/site/RequestPage.vue"),
            meta: {
                title: "Trespasse de Espaços"
            }
        },
        {
            path: '/search',
            name: 'properties',
            component: () => import("@/pages/site/property/HomePage.vue"),
            meta: {
                title: "Search Property"
            }
        },
        {
            path: '/property/:id',
            name: 'property-details',
            component: () => import("@/pages/site/property/DetailsPage.vue"),
            meta: {
                title: "Property Details"
            }
        },
        {
            path: '/agents',
            name: 'agents',
            component: () => import("@/pages/site/agent/HomePage.vue")
        },
        {
            path: '/agents/:id',
            name: 'agent-details',
            component: () => import("@/pages/site/agent/DetailsPage.vue"),
            meta: {
                title: 'Agent Products'
            }
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@/pages/auth/LoginPage.vue"),
            meta: {
                middleware: "guest",
                title: `Login`,
            },
        },

        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/pages/auth/RecoverPasswordPage.vue"),
            meta: {
                middleware: "guest",
            },
        },

        {
            path: '/agente',
            name: "be-agente",
            component: () => import("@/pages/admin/Agents/beAgent.vue"),
            meta: {
                title: 'Ser um Agente'
            }
        },
        {
            path: '/franquia',
            name: "franquia",
            component: () => import("@/pages/admin/Agents/franchiseShowForm.vue"),
            meta: {
                title: 'Franquia'
            }
        },

        {
            path: "/reset_password/:token",
            name: "recover-password",
            component: () => import("@/pages/auth/NewPasswordPage.vue"),
            meta: {
                middleware: "guest",
            },
        },
        {
            path: "/register",
            name: "register",
            component: () => import("@/pages/auth/RegisterPage.vue"),
            meta: {
                // middleware: "guest",
                title: `Nova Conta`,
            },
        },
        {
            path: "/register-agent",
            name: "register-agent",
            component: () => import("@/pages/auth/RegisterPage.vue"),
            meta: {
                // middleware: "guest",
                title: `Nova Conta`,
            },
        },
        {
            path: "/complete-register",
            name: "complete-register",
            component: () => import("@/pages/auth/CompleteProfile.vue"),
            meta: {
                middleware: "guest",
                title: `Nova Conta`,
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import("@/pages/site/profile/MyProfile.vue"),
            requiresAuth: true,
            meta: {
                title: "Profile"
            },
        },

        {
            path: '/update_credetials',
            name: 'update-credentials',
            component: () => import("@/pages/auth/UpdatePasswordPage.vue")
        },

        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: () => import("@/pages/site/profile/EditProfile.vue"),
            meta: {
                title: 'Edit Prodile'
            }
        },
    ],
};