import router from "@/router";
import axios from "axios";
// import router from "@/router";
const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    isPrescription: true,
    permissions: [],
    role: '',
    lang: 'pt_pt'

  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    isPrescription(state) {
      return state.isPrescription;
    },
    permissions(state) {
      return state.permissions;
    },

    role(state) {
      return state.role;
    },
    lang(state) {
      return state.lang;
    }

  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_PRESCRIPTION(state, value) {
      state.isPrescription = value;
    },

    SET_PERMISSION(state, value) {
      state.permissions = value;
    },

    SET_ROLE(state, value) {
      state.role = value;
    },
    SET_LANG(state, value) {
      state.lang = value
    }
  },
  actions: {
    login({ commit }, data) {
      // console.log(access_oken)
      const token = data.access_token;
      axios
        .get(URL_API + "user", {
          // .get("/api/user", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data }) => {
          
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("auth-token", token);

          commit("SET_USER", data.data.person);
          commit("SET_PERMISSION", data.data.person);
          commit("SET_ROLE", data.data.role[0]);
          commit("SET_AUTHENTICATED", true);
          //  router.push({path:'/dashboard'})
          // console.log(data.data.permissions);
          if (data.data.role[0] == "Superadmin") {
            // router.push({path:'/dashboard'})
            window.location.href = "/admin/dashboard";
          } else if (data.data.role[0] == "Agent") {
            // router.push({path:'/profile'})
            if (data.data.is_profile_complete == 0) {
              window.location.href = "/complete-register";
            } else {
              window.location.href = "/console";
            }
          } else {
            // router.push({path:'/'})
            window.location.href = "/";

          }

        })
        .catch(() => {
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
        });
    },

    updateUserData({ commit }, data) {
      commit("SET_USER", data);

    },

    prescription({ commit }, data) {

      commit("SET_PRESCRIPTION", data);
    },

    localLang({ commit }, data) {
      commit("SET_LANG", data);
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
    },
  },
};
