import AdminLayout from "@/layouts/AdminLayout.vue";
export const AdminRoutes = {
    path: "/admin",
    component: AdminLayout,
    meta: {
        middleware: "auth",
        requiresAuth: true,
        role: 'Superadmin'
    },
    children: [
        {
            path: '',
            component: () => import("@/pages/admin/Statistic/StatisticPage.vue"),
            name: "admin",
            meta: {
                title: "Dashboard"
            }
        },

        {
            path: 'dashboard',
            component: () => import("@/pages/admin/Statistic/StatisticPage.vue"),
            name: "admin.dashboard",
            meta: {
                title: "Dashboard"
            }
        },
        {
            path: 'profile',
            name: 'admin.profile',
            component: () => import("@/pages/admin/profile/MyProfile.vue"),
            // component: () => import("@/components/mainProfile.vue"),
            requiresAuth: true,
            meta: {
                title: "Profile"
            },
        },

        {
            path: 'profile',
            name: 'admin.profile-list',
            component: () => import("@/pages/admin/profile/ListProducts.vue"),
            meta: {
                title: 'Products'
            }
        },
        {
            path: 'add-product',
            name: 'admin.add-product',
            component: () => import("@/pages/admin/profile/addProducts.vue"),
            mata: {
                title: 'Products'
            }
        },
        {
            path: 'update-product/:id',
            component: () => import("@/pages/admin/profile/EditProduct.vue"),
            name: 'admin.update-my-product',
            meta: {
                title: 'Update Product'

            }
        },
        {
            path: "myData",
            name: 'admin.my-data',
            component: () => import("@/pages/admin/profile/MyProfile.vue"),
            meta: {
                title: 'Profile'
            }
        },
        {
            path: 'edit-profile',
            name: 'admin.edit-profile',
            component: () => import("@/pages/admin/profile/EditProfile.vue"),
            meta: {
                title: 'Profile'
            }
        },
        {
            path: 'update_password',
            component: () => import("@/pages/auth/UpdatePasswordPage.vue")
        },

        {
            path: 'adminProfile',
            name: 'admin.admin-profile',
            component: () => import("@/pages/admin/profile/MyProfile.vue"),
            meta: {
                title: 'Profile'
            }
        },
        {
            path: 'update_credetials',
            name: 'admin.update-credentials',
            component: () => import("@/pages/auth/UpdatePasswordPage.vue")
        },

        {
            path: 'edit-admin-profile',
            name: 'admin.edit-admin-profile',
            component: () => import("@/pages/admin/profile/EditProfile.vue"),
            meta: {
                title: 'Edit Prodile'
            }
        },
        {
            path: 'add-products',
            name: 'admin.add-products',
            component: () => import("@/pages/admin/Products/addProducts.vue"),
            meta: {
                title: "Products"
            }
        },
        {
            path: 'list-products',
            name: 'admin.list-products',
            component: () => import("@/pages/admin/Products/ListProducts.vue"),
            meta: {
                title: 'List-products'
            }
        },
        {
            path: 'list-contacts',
            name: 'admin.list-contacts',
            component: () => import("@/pages/admin/contacts/ListContact.vue"),
            meta: {
                title: 'List-products'
            }
        },
        {
            path: 'list-actions-log',
            name: 'admin.list-actions-log',
            component: () => import("@/pages/admin/actions-log/ListActionLog.vue"),
            meta: {
                title: 'List-products'
            }
        },
        {
            path: "list-available-products",
            name: "admin.list-available-products",
            component: () => import("@/pages/admin/Products/ListProductsAvailable.vue"),
            meta: {
                title: 'List-products'
            }
        },
        {
            path: 'edit-porduct/:query',
            name: 'admin.edit-product',
            component: () => import("@/pages/admin/Products/EditProduct.vue"),
            meta: {
                title: 'Edit Product'
            }
        },
        {
            path: 'add-agents',
            name: 'admin.add-agents',
            component: () => import("@/pages/admin/Agents/AgentsForm.vue"),
            meta: {
                title: 'Agents'
            }

        },
        {
            path: 'list-agents',
            name: 'admin.list-agents',
            component: () => import("@/pages/admin/Agents/ListAgents.vue"),
            meta: {
                title: 'Agents'
            }
        },
        {
            path: 'edit-agent/:id',
            name: 'admin.edit-agent',
            component: () => import("@/pages/admin/Agents/EditAgent.vue"),
            meta: {
                title: "Agent"
            }
        },

        {
            path: 'view-agent/:id',
            name: 'admin.view-agent',
            component: () => import("@/pages/admin/Agents/ViewAgent.vue"),
            meta: {
                title: "Agent"
            }
        },

        {
            path: 'add-proporsal',
            name: "admin.add-proposal",
            component: () => import("@/pages/admin/Proporsal/ProporsalPage.vue"),
            meta: {
                title: "Proporsal"
            }
        },
        {
            path: 'list-proporsal',
            name: "admin.list-proporsal",
            component: () => import("@/pages/admin/Proporsal/ListProporsal.vue"),
            meta: {
                title: "Proporsal"
            }
        },
        {
            path: 'update-proporsal/:id',
            name: "admin.edit-proporsal",
            component: () => import("@/pages/admin/Proporsal/EditProporsal.vue"),
            meta: {
                title: "Actualizar Proposta"
            }

        },
        {
            path: 'view-proporsal/:id',
            name: "admin.view-proposal",
            component: () => import("@/pages/admin/Proporsal/ViewProporsal.vue"),
            meta: {
                title: "Actualizar Proposta"
            }

        },
        {
            path: 'show-product-proporsal/:id',
            name: "admin.show-product-proposal",
            component: () => import("@/pages/admin/Proporsal/product_proporsal.vue"),
            meta: {
                title: "Propostas do Producto"
            }

        },

        {
            path: 'list-be-agent',
            name: "admin.list-be-agent",
            component: () => import("@/pages/admin/Agents/ListBeAgent.vue"),
            meta: {
                title: "Quero ser agente"
            }

        },

        {
            path: 'list-candidate',
            name: "admin.list-candidate",
            component: () => import("@/pages/admin/Agents/ListFranchise.vue"),
            meta: {
                title: "Listar Franquias"
            }

        }


    ]


};