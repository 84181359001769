export const EN = {

    BarMenu: {
        Home: "Home",
        Sell: "Sell",
        Buy: "Buy",
        Rent: "Rent",
        Account: "My Account",
        Languages: "Languages",
        Login: "Login",
        Logout: "Logout",
        Credentials: "Credentials",
        MyProduct: "My Products",
        Product: "Products",
        Add: "Add",
        List: "List",
        Categories: "Categories",
        Categorie: "Categorie",
        Agents: "Agents",
        Profile: "Profile",
        Agent: "Agent",
        ProporsalClients: 'Proposal',
        Agence: 'Agence',
        franchise: 'Franquia',
        BecomeAgent: "Become an Agent",
        ListProductAvailabe: 'Available Product',
        Candidate: 'Candidates',
        beAgent: 'Be Agent',
        RealEstate: "Real Estate",
        BuyRentProperty: "Buy/Rent property",
        Contact: "Contact",
        Type: "Type",

    },
    FormLogin: {
        ForgotPassword: "Forgot Password",
        ShowPassword: "Show Password",
        RemberMe: "Remember Me",
        Password: "Password of at least 8 digits, containing uppercase, lowercase, and digits ",
        AccessAccount: "Log in to your account",
        SignUp: "You don't have account yet",
        clickTitle: "Click Here",
        Access: "Login",
        Accessing: "Login",
        recoverEmail: "Retrieve your password",
        update: "update",
        updating: "updating...",
        LoginGoogle: "Login with Google",
        Or: "Or",
        Proceed: "Proceed",
        Email: "Email",
        PasswordLabel: "Password",
        Name: "Name",
        Contact: "Contact",
        Processing: "Processing",

    },

    HomePage: {
        Featured: "Featured",
        FindYourProduct: "Find your Product",
        RealState: " Buy",
        Land: "Arrendar",
        Popular: "Most Visited",
        News: "News",
        SearchProductLabel: 'Where?',
        SearchProductInput: 'Find Neighborhood or City or Province',
        Buy: "Sale",
        Rent: "Rent",
        Searching: "Searching",
        NoResults: "No results found",
        RealEstate: "Real Estate",

    },


    Footer: {
        aboutSite: "Are you looking for a space or want to buy a trespass, we are the ideal solution.",
        Resources: "Resources",
        Links: {
            Home: "Home",
            ProductDateils: "Product Dateils",
            Agents: "Agents",
            ContactUs: "Contact Us",
            HelpUs: "Help us improve the platform"
        },
        MyAccount: {
            MyAccount: "My Account",
            WhyTransapss: "Why - Traversing terrain",
            Contacts: "Contacts",
            PolicePrivicy: "Privacy Policy",
            AcessYouData: "Access to your personal data",
            PrivacyState: "Privacy Statement",
            CondOfUse: "Conditions of use",
            SafetyTips: "Safety Tips",
            Newsletter: "Newsletter",
            KeepInformed: "We want to keep you informed of our news sign up",
            YourEmail: "Your Email",
            SignUp: "Sign up",
            FallowUs: "Fallow-Us"
        }
    },

    PageDateils: {
        Description: "Description",
        Dateils: "Dateils",
        Divisions: "Divisions",
        Maps: 'Maps',
        ContactAgent: "Contact Agent",
        Comment: "Comment",
        Proposal: "Proposal",
        Bedroom: "Bedrooms",
        Lift: "Lift",
        Share: "Share",
        Parking: "Park",
        ProductAvailableRent: "For Rent",
        ProductAvailableSell: "For Sell"
    },

    PageFilter: {
        InfoStatusMessage: "There are no properties that meet your search criteria!",
        InfoMessageSmall: "Please try a new search.",
        Search: "Search",
        SearchByTitle: "Search by title",

        FilterBtnText: "Filters",
        ServiceBtnText: "Service P",
        Price: "Price",
        PriceMin: "Price Min",
        PriceMax: "Price Max",
        Location: "Location",
        DefineIntervalPrice: "Set your price range",
        BtnAply: "Aply",
        BtnRent: "Rent",
        MoreFilterTitle: "More Filters",
        SeeMoreTitle: "See More",
        WcLabel: "Bathrooms",
        AreaLabel: "Area m2",
        Lift: "Lift",
        MarketState: "Market State",
        CategorieRealEstate: "Property Category",
        Published: "Published",
        ServicePersonalized: "Customized Services",
        ProductCategorie: "Product Category",
        RentOrBuy: "Rent/Buy",
        Title: "Title",
        district: "Select District",
        ProvinceLabel: "Select Province",
        PlaceHolderCategorie: "Please select the category",
        PlaceHolderCategorieRentOrBuy: "Please Select if You Want to Rent/Buy",
        PlaceHolderContact: "Inform your contact",
        PlaceHolderEmail: "Enter your email",
        Loading: "Loading more products",
        Any: "Any",
        MarketStateLabel: "Select Market State",
        CleanTitle: "Clean",
        DescriptionP: "Please Leave Your Description here, it also mentions the nearest infrastructures you want to facilitate our agents...",
        Send: "Send",
        Cancel: "Cancel",
        Description: "Description",
        SelectTime: "Please select the time",
        SortBy: "Ordenar Por",
        Default: "Default",
        PriceLowToHigh: "Price (Low to High)",
        PriceHighToLow: "Price (High to Low)",
        MostViewed: "Most Viewed",
        Newest: "Newest",
        Oldest: "Oldest",
        FindResult: "Articles Found",

        PriceRange: "Price Range",
        Filter: "Filter",
        Reset: "Reset",
        Type: "Type",
        Types: "Types",
        Category: "Category",
        Categories: "Categories",
        Proposity: "What do you want?",
        ApplyFilters: "Apply Filters",


    },
    AdminDashBoard: {
        TotalProduct: "Total Products",
        TotalAgents: "Total Agents",
        ProductSell: "Products Sell",
        ProductRent: "Products Rent",
        Sell: "Sell",
        Rent: "Rent",
        LastFiveProducts: "Last 5 Product",
        Type: "Type",
        Categorie: "Categorie",
        Available: "Available",
        Date: "Date",
        Neighborhood: "Neighborhood",
        DragAndDropPlaceholder: "Drag and drop the image here or",
        DragAndDropPlaceholderAgent: "Drag and drop the profile image here or",
        DragAndDrop: "Drag and drop the image",
        DragAndDropAgent: "Drag and drop the profile image",
        DragAndDropPlaceholderV: "Drag and drop the video",
        DragAndDropPlaceholderVideo: "Drag and drop the video or",
        Choose: "Choose",
        Floor: "Piso",
        BuildYear: "Year of construction",
        BruteArea: "Gross Area*",
        Parking: "Parking lot",
        Back: "Back",
        AreaLoteTotal: "Total Plot Area",
        AreaUtil: "Usable Area",
        Submit: "Submit",
        Next: "Next",
        Visits: "Visits"


    },

    FormAgents: {
        Title: "Register New Agent",
        CompleteName: "Complete Name",
        BirthDate: "Birth Date",
        Gender: "Gender",
        MaritalStatus: "Marital Status",
        BI: "BI",
        AlternativeContact: "Alternative Contact*"
    },

    Geral: {
        Type: "Type",

        Sell: "Sell",
        Buy: "Buy",
        Rent: "Rent",
    },
    mpesa_gateway_instructions: "Enter your M-Pesa phone number (84/85) and click 'Recharge', then confirm the pin on your phone.",

}