import SellerLayout from "@/layouts/SellerLayout.vue";
export const SellerRoutes = {
    path: "/console",
    component: SellerLayout,
    meta: {
        middleware: "auth",
        requiresAuth: true,
    },

    children: [
        {
            path: '',
            component: () => import("@/pages/seller/statistic/StatisticPage.vue"),
            name: "seller",
            meta: {
                title: "Dashboard"
            }
        },

        {
            path: 'dashboard',
            component: () => import("@/pages/seller/statistic/StatisticPage.vue"),
            name: "seller.dashboard",
            meta: {
                title: "Dashboard"
            }
        },
        {
            path: 'profile',
            name: 'seller.profile',
            component: () => import("@/pages/seller/profile/MyProfile.vue"),
            requiresAuth: true,
            meta: {
                title: "Profile"
            },
        },

        {
            path: 'update_credetials',
            name: 'seller.update-credentials',
            component: () => import("@/pages/auth/UpdatePasswordPage.vue")
        },

        {
            path: 'edit-profile',
            name: 'seller.edit-profile',
            component: () => import("@/pages/seller/profile/EditProfile.vue"),
            meta: {
                title: 'Edit Prodile'
            }
        },
        {
            path: 'notifications',
            name: 'seller.notifications',
            component: () => import("@/pages/seller/notifications/HomePage.vue"),
            meta: {
                title: 'Notifications'
            }
        },
        // Products
        {
            path: 'add-product',
            name: 'seller.add-product',
            component: () => import("@/pages/seller/products/AddProducts.vue"),
            meta: {
                title: "Products"
            }
        },
        {
            path: 'products',
            name: 'seller.products',
            component: () => import("@/pages/seller/products/ListProducts.vue"),
            meta: {
                title: 'Products'
            }
        },
        {
            path: 'edit-porduct/:query',
            name: 'seller.edit-product',
            component: () => import("@/pages/seller/products/EditProduct.vue"),
            meta: {
                title: 'Edit Product'
            }
        },
        {
            path: 'show-porduct/:id',
            name: 'seller.show-product',
            component: () => import("@/pages/seller/products/DetailsPage.vue"),
            meta: {
                title: 'Edit Product'
            }
        },

        // Agents
        {
            path: 'add-agents',
            name: 'seller.add-agents',
            component: () => import("@/pages/seller/agents/AgentsForm.vue"),
            meta: {
                title: 'Agents'
            }

        },
        {
            path: 'list-agents',
            name: 'seller.list-agents',
            component: () => import("@/pages/seller/agents/ListAgents.vue"),
            meta: {
                title: 'Agents'
            }
        },
        
        {
            path: 'edit-agent/:id',
            name: 'seller.edit-agent',
            component: () => import("@/pages/seller/agents/EditAgent.vue"),
            meta: {
                title: "Agent"
            }
        },
        

        {
            path: 'view-agent/:id',
            name: 'seller.view-agent',
            component: () => import("@/pages/seller/agents/ViewAgent.vue"),
            meta: {
                title: "Agent"
            }
        },

        {
            path: 'add-proporsal',
            name: "seller.add-proposal",
            component: () => import("@/pages/seller/proporsal/ProporsalPage.vue"),
            meta: {
                title: "Proporsal"
            }
        },
        {
            path: 'list-proporsal',
            name: "seller.list-proposal",
            component: () => import("@/pages/seller/proporsal/ListProporsal.vue"),
            meta: {
                title: "Proporsal"
            }
        },
        {
            path: 'update-proporsal/:id',
            name: "seller.edit-proposal",
            component: () => import("@/pages/seller/proporsal/EditProporsal.vue"),
            meta: {
                title: "Actualizar Proposta"
            }

        },
        {
            path: 'view-proporsal/:id',
            name: "seller.view-proposal",
            component: () => import("@/pages/seller/proporsal/ViewProporsal.vue"),
            meta: {
                title: "Actualizar Proposta"
            }

        },
        {
            path: 'show-product_proporsal/:id',
            name: "seller.show-product-proposal",
            component: () => import("@/pages/seller/proporsal/product_proporsal.vue"),
            meta: {
                title: "Propostas do Producto"
            }

        },

        {
            path: 'list-beAgent',
            name: "seller.list-be-agent",
            component: () => import("@/pages/seller/agents/ListBeAgent.vue"),
            meta: {
                title: "Quero ser agente"
            }

        },

        {
            path: 'list-candidate',
            name: "seller.list-candidate",
            component: () => import("@/pages/seller/agents/ListFranchise.vue"),
            meta: {
                title: "Listar Franquias"
            }

        }


    ]

    //     },

    // ]
};